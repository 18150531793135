::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #1c2282;
}
::-webkit-scrollbar-thumb:hover {
  background: #3a41b7;
}
div.playerDialog::-webkit-scrollbar-thumb {
  background:#44e9fd;
}
div.playerDialog::-webkit-scrollbar-thumb:hover {
  background: #278591;
}
html {
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  scroll-behavior: smooth;
}
body {
  font-family: "";
}
body {
  margin: 0;
  font-family: "PingFangTC-Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0c1540;
  background-image: url("../src/assets/images/bg.jpg");
  -webkit-text-size-adjust: 100%; /* stop ios zooming text  on orientation change */
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-family: Tahoma, Helvetica, Arial, "Microsoft Yahei", "微软雅黑", STXihei,
    "华文细黑", sans-serif;
}
option {
  color: black !important;
}
.swiper-wrap {
  z-index: 1;
  position: relative;
  height: 520px;
  overflow: hidden;
}
.discuss-swiper {
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  height: 520px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  margin: 0;
}
@media screen and (max-width: 1680px) {
  .discuss-swiper {
    height: 440px;
  }
  .swiper-wrap {
    height: 450px;
  }
  .swiperImg{
    height: 415px!important;
  }
}
@media screen and (max-width: 1580px) {
  .discuss-swiper {
    height: 408px;
  }
  .swiper-wrap {
    height: 420px;
  }
  .swiperImg{
    height: 383px!important;
  }
}
@media screen and (max-width: 1540px) {
  .discuss-swiper {
    height: 398px;
  }
  .swiper-wrap {
    height: 400px;
  }
  .swiperImg{
    height: 373px!important;
  }
}
@media screen and (max-width: 1480px) {
  .discuss-swiper {
    height: 385px;
  }
  .swiper-wrap {
    height: 390px;
  }
  .swiperImg{
    height: 359px!important;
  }
}
@media screen and (max-width: 1380px) {
  .discuss-swiper {
    height: 360px;
  }
  .swiper-wrap {
    height: 370px;
  }
  .swiperImg{
    height: 334px!important;
  }
}
@media screen and (max-width: 1340px) {
  .discuss-swiper {
    height: 350px;
  }
  .swiper-wrap {
    height: 350px;
  }
  .swiperImg{
    height: 324px!important;
  }
}
@media screen and (max-width: 1280px) {
  .discuss-swiper {
    height: 330px;
  }
  .swiper-wrap {
    height: 330px;
  }
  .swiperImg{
    height: 303px!important;
  }
}
.swiper-wrap
  .discuss-swiper
  .swiper-wrapper
  .swiper-slide-active
  .discuss_caption {
  display: block;
}
.swiper-wrap .discuss-swiper .swiper-wrapper {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 0px;
}

/*Table Pagination */
.MuiPagination-ul button {
  border: 1px solid #fff;
  color: #fff;
}
.MuiPaginationItem-root {
  color: #fff !important;
}
.MuiPaginationItem-page.Mui-selected {
  background-color: #0a0e59 !important;
}

/* SelectCss */
.select_wrapper .MuiFormControl-root {
  height: 45px !important;
}
.select_wrapper .MuiInputBase-root {
  height: 40px !important;
  margin-top: 3px !important;
}
.select_wrapper select {
  height: 40px !important;
  padding: 0px 14px !important;
}
.MuiSelect-icon.Mui-disabled {
  fill: #312e2e !important;
}

/* pagination */
.MuiPaginationItem-sizeSmall {
  height: 50px !important;
  padding: 0px 20px !important;
  font-size: 20px !important;
  min-width: 30px !important;
  margin: 5px !important;
}

/* SwiperPagination */
.swiper-pagination-bullets {
  bottom: 26px !important;
}
.discuss-swiper .swiper-pagination .swiper-pagination-switch,
.discuss-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 16px !important;
  height: 16px !important;
  display: inline-block !important;
  margin-left: 8px !important;
  border-radius: 50% !important;
  background: #fff !important;
  border: 2px solid #43e9f8 !important;
  opacity: 1;
  cursor: pointer;
  outline: none;
}
.discuss-swiper
  .swiper-pagination
  .swiper-pagination-switch.swiper-active-switch,
.footer
  .m-joins
  .m-swiper
  .swiper-pagination
  .swiper-pagination-switch.swiper-pagination-bullet-active,
.discuss-swiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-active-switch,
.discuss-swiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 2px solid #285e79 !important;
  background: #43e9f8 !important;
  cursor: default;
  outline: none;
}
/* MobileSwiperPagination */
.mobile-slider-pagination .slick-active div {
  border: 2px solid #285e79 !important;
  background: #43e9f8 !important;
}
.mobile-slider-pagination li div {
  background: #fff;
  border: 2px solid #43e9f8;
}
